<template>
  <headBackBar :iCustomizdde="true" :icon="true">
    <template v-slot:left-icon>
      <van-icon name="arrow-left" @click="$fun.routerToPage('/user/index')" />
    </template>
    <template #default>
      <div class="search">
        <inputBoxVue
          type="text"
          :change="changeinput"
          :placeholder="$fanyi('可根据订单号搜索')"
          v-model="searchkeyword"
          :keydownEnter="search"
        />
      </div>
    </template>
    <!--订单单列表 -->
  </headBackBar>
  <dialogVue ref="dialogRef" />
  <div class="content">
    <van-tabs
      v-model:active="active"
      @click-tab="clicktabs"
      title-inactive-color="#000000"
      line-width="30px"
      animated
      title-active-color="#B4272B"
    >
      <!-- 临时保存 -->
      <!-- <van-tab :title="$fanyi('临时保存') + '(' + tabnum.save + ')'">

      </van-tab> -->
      <!-- 临时保存 -->
      <van-tab :title="$fanyi('临时保存') + ' (' + table.temporary + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.temporaryrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.temporaryfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                v-for="(item, index) in orderdata.temporarylist"
                :key="item.user_id"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text"
                    >{{
                      item.created_at
                        ? specifiedFormatDate({
                            date: item.created_at,
                            format: "yyyy-mm-dd hh:min",
                          })
                        : "一一一一一"
                    }}
                  </span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.pay_at
                      ? specifiedFormatDate({
                          date: item.pay_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("购买完成时间") }}：</span>
                  <span class="right-text">{{
                    item.buy_success_at
                      ? specifiedFormatDate({
                          date: item.buy_success_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <div class="upload quxiao" @click.stop="delOrder(item, index)">
                  {{ $fanyi("删除") }}
                </div>
              </div>
              <div
                class="noData"
                v-if="!orderdata.temporarylist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 全部 -->
      <van-tab :title="$fanyi('全部') + ' (' + table.all + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.allrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.allfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                v-for="(item, index) in orderdata.alllist"
                :key="item.user_id"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text">{{
                    item.created_at
                      ? specifiedFormatDate({
                          date: item.created_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.pay_at
                      ? specifiedFormatDate({
                          date: item.pay_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("购买完成时间") }}：</span>
                  <span class="right-text">{{
                    item.buy_success_at
                      ? specifiedFormatDate({
                          date: item.buy_success_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <div
                  v-if="
                    ['临时保存待提出', '等待付款'].includes(item.status_name)
                  "
                  class="upload quxiao quanbu"
                  @click.stop="delOrder(item, index)"
                >
                  {{ $fanyi("删除") }}
                </div>
                <div
                  class="fukuan"
                  v-if="
                    item.status_name == '等待付款' && item.part_pay_status == 0
                  "
                  @click.stop="payment(item)"
                >
                  {{ $fanyi("立即付款") }}
                </div>
              </div>
              <div class="noData" v-if="!orderdata.alllist.length && !loading">
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 报价中 -->
      <van-tab :title="$fanyi('报价中') + ' (' + table.offer + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.offerrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.offerfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                v-for="item in orderdata.offerlist"
                :key="item.user_id"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text">{{
                    item.created_at
                      ? specifiedFormatDate({
                          date: item.created_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.pay_at
                      ? specifiedFormatDate({
                          date: item.pay_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("购买完成时间") }}：</span>
                  <span class="right-text">{{
                    item.buy_success_at
                      ? specifiedFormatDate({
                          date: item.buy_success_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <div
                class="noData"
                v-if="!orderdata.offerlist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 等待付款 -->
      <van-tab :title="$fanyi('等待付款') + ' (' + table.payment + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.paymentrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.paymentfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
                v-for="(item, index) in orderdata.paymentlist"
                :key="item.user_id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
              </div>
              <div
                class="noData"
                v-if="!orderdata.paymentlist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 正在购买 -->
      <van-tab :title="$fanyi('正在购买') + ' (' + table.purchase + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.purchaserefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.purchasefinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
                v-for="item in orderdata.purchaselist"
                :key="item.user_id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text">{{
                    item.created_at
                      ? specifiedFormatDate({
                          date: item.created_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.pay_at
                      ? specifiedFormatDate({
                          date: item.pay_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("购买完成时间") }}：</span>
                  <span class="right-text">{{
                    item.buy_success_at
                      ? specifiedFormatDate({
                          date: item.buy_success_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <div
                class="noData"
                v-if="!orderdata.purchaselist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 购买完成 -->
      <van-tab
        :title="$fanyi('购买完成') + ' (' + table.completepurchase + ')'"
      >
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.completerefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.completefinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
                v-for="item in orderdata.completepurchaselist"
                :key="item.user_id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text">{{
                    item.created_at
                      ? specifiedFormatDate({
                          date: item.created_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.pay_at
                      ? specifiedFormatDate({
                          date: item.pay_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("购买完成时间") }}：</span>
                  <span class="right-text">{{
                    item.buy_success_at
                      ? specifiedFormatDate({
                          date: item.buy_success_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <div
                class="noData"
                v-if="!orderdata.completepurchaselist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 出货完成 -->
      <van-tab
        :title="$fanyi('出货完成') + ' (' + table.completedelivery + ')'"
      >
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.deliveryrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.deliveryfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
                v-for="item in orderdata.completedeliverylist"
                :key="item.user_id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text">{{
                    item.created_at
                      ? specifiedFormatDate({
                          date: item.created_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("付款时间") }}：</span>
                  <span class="right-text">{{
                    item.pay_at
                      ? specifiedFormatDate({
                          date: item.pay_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("购买完成时间") }}：</span>
                  <span class="right-text">{{
                    item.buy_success_at
                      ? specifiedFormatDate({
                          date: item.buy_success_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
              </div>
              <div
                class="noData"
                v-if="!orderdata.completedeliverylist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
      <!-- 已取消 -->
      <van-tab :title="$fanyi('已取消') + ' (' + table.cancel + ')'">
        <div class="main">
          <van-pull-refresh
            v-model="orderdata.cancelrefreshing"
            @refresh="onRefresh"
            head-height="100"
            :loosing-text="$fanyi('释放即可刷新...')"
            :pulling-text="$fanyi('下拉刷新')"
            :loading-text="$fanyi('加载中')"
            :success-text="$fanyi('加载成功')"
          >
            <van-list
              v-model:loading="loading"
              :loading-text="$fanyi('加载中')"
              :finished="orderdata.cancelfinished"
              :finished-text="$fanyi('')"
              @load="onLoad"
            >
              <div
                class="item"
                @click.stop="
                  $fun.routerToPage('/OrderDetails?order_sn=' + item.order_sn)
                "
                v-for="item in orderdata.cancellist"
                :key="item.user_id"
              >
                <div class="title">
                  <div
                    class="Singlenumbe"
                    v-longpress="
                      () => {
                        copySn(item.order_sn);
                      }
                    "
                  >
                    {{ $fanyi("订单号") }}：{{
                      item.order_sn ? item.order_sn : "一一一一一"
                    }}
                  </div>
                  <div class="state">
                    {{ $fanyi(changShowName(item.status_name)) }}
                  </div>
                </div>
                <p>
                  <span class="left-title">{{ $fanyi("商品番数") }}：</span>
                  <span class="right-text">{{ item.all_count }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("商品数量") }}：</span>
                  <span class="right-text">{{ item.goods_sum }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("订单创建时间") }}：</span>
                  <span class="right-text">{{
                    item.created_at
                      ? specifiedFormatDate({
                          date: item.created_at,
                          format: "yyyy-mm-dd hh:min",
                        })
                      : "一一一一一"
                  }}</span>
                </p>
                <p>
                  <span class="left-title">{{ $fanyi("取消时间") }}：</span>
                  <span class="right-text">{{
                    item.cancel_at || "一一一一一"
                  }}</span>
                </p>
                <div
                  class="upload quxiao"
                  @click.stop="truedelOrder(item, index)"
                >
                  {{ $fanyi("删除") }}
                </div>
              </div>
              <div
                class="noData"
                v-if="!orderdata.cancellist.length && !loading"
              >
                <div>
                  <img :src="require('@/assets/icon/dd.png')" alt="" />
                  <span>{{ $fanyi("暂无相关订单") }}</span>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </van-tab>
    </van-tabs>
  </div>
  <!-- 内容区域 -->
</template>
<script setup>
import dialogVue from "@/components/Dialog";
import inputBoxVue from "@/components/inputBox/index.vue";
import headBackBar from "../../../../components/headGoBack/index.vue";
import { getCurrentInstance, ref } from "vue";
import { Toast } from "vant";
import { useRoute } from "vue-router";
import { specifiedFormatDate } from "../../../../utlis/date";
const route = useRoute();

const { proxy } = getCurrentInstance();
const searchkeyword = ref("");
// 临时保存 0 全部 1 报价中 2 等待付款 3 正在购买4 购买完成 5  出货完成 6 已取消7
// if(route)
// console.log(route);
const active = ref(0);
// 状态
const state = ref("");
const pageSize = ref(20); // 每页条数
// 判断是那个页面来的
if (route.query.type == "temporary") {
  active.value = 0;
} else if (route.query.type == "offer") {
  active.value = 2;
} else if (route.query.type == "payment") {
  active.value = 3;
} else if (route.query.type == "purchase") {
  active.value = 4;
} else if (route.query.type == "completepurchase") {
  active.value = 5;
} else if (route.query.type == "completedelivery") {
  active.value = 6;
} else if (route.query.type == "cancel") {
  active.value = 7;
}
// 加载动画
Toast.loading({
  duration: 0,
  message: proxy.$fanyi("加载中..."),
  forbidClick: true,
});
const changShowName = (v) => {
  switch (v) {
    case "临时保存待提出":
      return "临时保存";
      break;

    default:
      return v;
      break;
  }
};
// 定义table数据
const table = ref({
  temporary: 0, //临时保存
  all: 0, //全
  offer: 0, //报价中
  payment: 0, // 等待付款
  purchase: 0, // 正在购买
  completepurchase: 0, //购买完成
  completedelivery: 0, //出货完成
  cancel: 0, // 取消
});

// 获取订单列表table数据

// 点击tab触发的事件
const clicktabs = (val) => {
  active.value = val.name;
  // 进项判断有没有进行搜索
  // 临时保存 0 全部 1 报价中 2 等待付款 3 正在购买4 购买完成 5  出货完成 6 已取消7
  if (orderstate.value == "临时保存待提出") {
    active.value = 0;
    orderdata.value.temporarypage = 0;
    orderdata.value.temporaryfinished = false;
  } else if (orderstate.value == "报价中") {
    active.value = 2;
    orderdata.value.offerpage = 0;
    orderdata.value.offerfinished = false;
  } else if (
    orderstate.value == "等待付款" ||
    orderstate.value == "待财务确认" ||
    orderstate.value == "待付尾款" ||
    orderstate.value == "首付款待财务确认"
  ) {
    active.value = 3;
    orderdata.value.paymentpage = 0;
    orderdata.value.paymentfinished = false;
  } else if (orderstate.value == "正在购买") {
    active.value = 4;
    orderdata.value.purchasepage = 0;
    orderdata.value.purchasefinished = false;
  } else if (orderstate.value == "采购完了") {
    active.value = 5;
    orderdata.value.completepurchasepage = 0;
    orderdata.value.completefinished = false;
  } else if (orderstate.value == "出货完成") {
    active.value = 6;
    orderdata.value.completedeliverypage = 0;
    orderdata.value.deliveryfinished = false;
  } else if (orderstate.value == "已取消") {
    active.value = 7;
    orderdata.value.cancelpage = 0;
    orderdata.value.cancelfinished = false;
  }
};
const getTableamount = async () => {
  const res = await proxy.$api.ordersSum();
  if (res.code !== 0) {
    Toast.fail(proxy.$fanyi(res.msg));
    Toast.clear();
    return;
  }
  Toast.clear();
  res.data.forEach((item) => {
    if (item.name == "临时保存待提出") {
      table.value.temporary = item.count;
    } else if (item.name == "报价中") {
      table.value.offer = item.count;
    } else if (item.name == "等待付款") {
      table.value.payment = item.count;
    } else if (item.name == "正在购买") {
      table.value.purchase = item.count;
    } else if (item.name == "采购完了") {
      table.value.completepurchase = item.count;
    } else if (item.name == "出货完成") {
      table.value.completedelivery = item.count;
    } else if (item.name == "已取消") {
      table.value.cancel = item.count;
    } else if (item.name == "全部") {
      table.value.all = item.count;
    }
  });
};

// 定义每个列表数据
const loading = ref(false);
const orderdata = ref({
  // 临时把保存数据
  temporarylist: [],
  temporarypage: 0,
  temporaryfinished: false,
  temporaryrefreshing: false,
  // 全部数据
  alllist: [],
  allpage: 0,
  allfinished: false,
  allrefreshing: false,
  // 报价中
  offerlist: [],
  offerpage: 0,
  offerfinished: false,
  offerrefreshing: false,
  // 等待付款
  paymentlist: [],
  paymentpage: 0,
  paymentfinished: false,
  paymentrefreshing: false,
  // 正在购买
  purchaselist: [],
  purchasepage: {},
  purchasefinished: false,
  purchaserefreshing: false,
  // 购买完成 采购完了
  completepurchaselist: [],
  completepurchasepage: 0,
  completefinished: false,
  completerefreshing: false,
  // 出货完成
  completedeliverylist: [],
  completedeliverypage: 0,
  deliveryfinished: false,
  deliveryrefreshing: false,
  //取消
  cancellist: [],
  cancelpage: 0,
  cancelfinished: false,
  cancelrefreshing: false,
});
// 取消订单
const delOrder = (item, i) => {
  proxy.$refs.dialogRef.open({
    text: proxy.$fanyi("是否取消选中的订单"),
    successBtn: proxy.$fanyi("确定"),
    cancelBtn: proxy.$fanyi("取消"),
    successMethods: () => {
      proxy.$api
        .orderDelete({
          order_sn: item.order_sn,
          delete_reason: "h5删除,不用填写原因",
        })
        .then((res) => {
          ////console.log('事件名',res)
          if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
          proxy.$toast.success(proxy.$fanyi("操作成功"));
          //接下来的操作
          proxy.$toast.success(proxy.$fanyi(res.data.msg));
          // switch (active.value) {
          //   case 0:
          //     orderdata.value.temporarylist = [];
          //     orderdata.value.temporarypage = 0;
          //     orderdata.value.temporaryfinished = false;
          //     break;
          //   case 1:
          //     orderdata.value.alllist = [];
          //     orderdata.value.allpage = 0;
          //     orderdata.value.allfinished = false;
          //     break;
          //   case 3:
          //     orderdata.value.paymentlist = [];
          //     orderdata.value.offerpage = 0;
          //     orderdata.value.offerfinished = false;
          //     break;
          //   default:
          //     break;
          // }
          switch (active.value) {
            case 0:
              orderdata.value.temporarylist.splice(i, 1);
              break;
            case 1:
              orderdata.value.alllist.splice(i, 1);
              break;
            case 3:
              orderdata.value.paymentlist.splice(i, 1);
              break;
            default:
              break;
          }
          orderdata.value.cancellist = [];
          orderdata.value.cancelpage = 0;
          orderdata.value.cancelfinished = false;
          getTableamount();
        });
    },
  });
};
// 删除订单
const truedelOrder = (item, i) => {
  proxy.$refs.dialogRef.open({
    text: proxy.$fanyi("是否取消选中的订单"),
    successBtn: proxy.$fanyi("确定"),
    cancelBtn: proxy.$fanyi("取消"),
    successMethods: () => {
      proxy.$api
        .orderorderRemove({
          order_sn: item.order_sn,
          remove_reason: "h5删除,不用填写原因",
        })
        .then((res) => {
          ////console.log('事件名',res)
          if (res.code != 0)
            return proxy.$message.error(proxy.$fanyi(res.data.msg));
          proxy.$toast.success(proxy.$fanyi("操作成功"));
          //接下来的操作
          proxy.$toast.success(proxy.$fanyi(res.data.msg));
          // switch (active.value) {
          //   case 0:
          //     orderdata.value.temporarylist = [];
          //     orderdata.value.temporarypage = 0;
          //     orderdata.value.temporaryfinished = false;
          //     break;
          //   case 1:
          //     orderdata.value.alllist = [];
          //     orderdata.value.allpage = 0;
          //     orderdata.value.allfinished = false;
          //     break;
          //   case 3:
          //     orderdata.value.paymentlist = [];
          //     orderdata.value.offerpage = 0;
          //     orderdata.value.offerfinished = false;
          //     break;
          //   default:
          //     break;
          // }
          switch (active.value) {
            case 0:
              orderdata.value.temporarylist.splice(i, 1);
              break;
            case 1:
              orderdata.value.alllist.splice(i, 1);
              break;
            case 3:
              orderdata.value.paymentlist.splice(i, 1);
              break;
            default:
              break;
          }
          orderdata.value.cancellist = [];
          orderdata.value.cancelpage = 0;
          orderdata.value.cancelfinished = false;
          getTableamount();
        });
    },
  });
};
// 页面加载
const onLoad = async () => {
  getTableamount();
  // 临时保存 0 全部 1 报价中 2 等待付款 3 正在购买 4 购买完成 5  出货完成 6 已取消7
  let page = 0;
  if (active.value == 0) {
    state.value = "临时保存待提出";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.temporaryfinished == true) {
      orderdata.value.temporaryrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.temporarylist.length == 0) {
      orderdata.value.temporarypage = 1;
    } else {
      orderdata.value.temporarypage += 1;
    }
    page = orderdata.value.temporarypage;
  }
  if (active.value == 1) {
    state.value = "全部";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.allfinished == true) {
      orderdata.value.allrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.alllist.length == 0) {
      orderdata.value.allpage = 1;
    } else {
      orderdata.value.allpage += 1;
    }
    page = orderdata.value.allpage;
  }
  if (active.value == 2) {
    state.value = "报价中";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.offerfinished == true) {
      orderdata.value.offerrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.offerlist.length == 0) {
      orderdata.value.offerpage = 1;
    } else {
      orderdata.value.offerpage += 1;
    }
    page = orderdata.value.offerpage;
  }
  if (active.value == 3) {
    state.value = "等待付款";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.paymentfinished == true) {
      orderdata.value.paymentrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.paymentlist.length == 0) {
      orderdata.value.paymentpage = 1;
    } else {
      orderdata.value.paymentpage += 1;
    }
    page = orderdata.value.paymentpage;
  }
  if (active.value == 4) {
    state.value = "正在购买";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.purchasefinished == true) {
      orderdata.value.purchaserefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.purchaselist.length == 0) {
      orderdata.value.purchasepage = 1;
    } else {
      orderdata.value.purchasepage += 1;
    }
    page = orderdata.value.purchasepage;
  }
  if (active.value == 5) {
    state.value = "采购完了";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.completefinished == true) {
      orderdata.value.completerefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.completepurchaselist.length == 0) {
      orderdata.value.completepurchasepage = 1;
    } else {
      orderdata.value.completepurchasepage += 1;
    }
    page = orderdata.value.completepurchasepage;
  }
  if (active.value == 6) {
    state.value = "出货完成";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.deliveryfinished == true) {
      orderdata.value.deliveryrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.completedeliverylist.length == 0) {
      orderdata.value.completedeliverypage = 1;
    } else {
      orderdata.value.completedeliverypage += 1;
    }
    page = orderdata.value.completedeliverypage;
  }
  if (active.value == 7) {
    state.value = "已取消";
    // 进行判断如果数据加载完了，下拉升刷新不执行
    if (orderdata.value.cancelfinished == true) {
      orderdata.value.cancelrefreshing = false;
      Toast(proxy.$fanyi("没有更多了"));
      return;
    }
    if (orderdata.value.cancelfinished.length == 0) {
      orderdata.value.cancelpage = 1;
    } else {
      orderdata.value.cancelpage += 1;
    }
    page = orderdata.value.cancelpage;
  }
  let local_active = active.value;
  // 获取订单列表
  const res = await proxy.$api.orderList({
    status_name: state.value,
    page: page,
    pageSize: pageSize.value,
  });
  // 关闭loading
  Toast.clear();
  // 请求失败判断关闭加载效果   // 临时保存 0 全部 1 报价中 2 等待付款 3 正在购买 4 购买完成 5  出货完成 6 已取消7

  if (res.code !== 0) {
    loading.value = false;
    if (active.value == 0) {
      orderdata.value.temporaryrefreshing = false;
      orderdata.value.temporaryfinished = true;
    } else if (active.value == 1) {
      orderdata.value.allrefreshing = false;
      orderdata.value.allfinished = true;
    } else if (active.value == 2) {
      orderdata.value.offerrefreshing = false;
      orderdata.value.offerfinished = true;
    } else if (active.value == 3) {
      orderdata.value.paymentrefreshing = false;
      orderdata.value.paymentfinished = true;
    } else if (active.value == 4) {
      orderdata.value.purchaserefreshing = false;
      orderdata.value.purchasefinished = true;
    } else if (active.value == 5) {
      orderdata.value.completerefreshing = false;
      orderdata.value.completefinished = true;
    } else if (active.value == 6) {
      orderdata.value.deliveryrefreshing = false;
      orderdata.value.deliveryfinished = true;
    } else if (active.value == 7) {
      orderdata.value.cancelrefreshing = false;
      orderdata.value.cancelfinished = true;
    }
    Toast.fail(proxy.$fanyi(res.msg));
    return;
  }
  console.log(active.value, local_active);

  // 请求成功后再次进行判断，渲染那个状态数据
  // loading.value = false
  if (active.value == 0) {
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.temporarypage = 0;
      return;
    }
    // 判断是下刷新海是触底  临时保存
    if (orderdata.value.temporaryrefreshing == true) {
      orderdata.value.temporarylist = [
        ...res.data?.data,
        ...orderdata.value.temporarylist,
      ];
    } else {
      orderdata.value.temporarylist = [
        ...orderdata.value.temporarylist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    orderdata.value.temporaryrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.temporarylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.temporaryfinished = true;
    }
  } else if (active.value == 1) {
    // 判断是下刷新海是触底   全部

    if (orderdata.value.allrefreshing == true) {
      orderdata.value.alllist = [...res.data?.data, ...orderdata.value.alllist];
    } else {
      orderdata.value.alllist = [...orderdata.value.alllist, ...res.data?.data];
    }
    loading.value = false;
    orderdata.value.allrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.alllist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.allfinished = true;
    }
  } else if (active.value == 2) {
    //
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.offerpage = 0;

      return;
    }
    // 判断是下刷新海是触底  报价中
    if (orderdata.value.offerrefreshing == true) {
      orderdata.value.offerlist = [
        ...res.data?.data,
        ...orderdata.value.offerlist,
      ];
    } else {
      orderdata.value.offerlist = [
        ...orderdata.value.offerlist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    orderdata.value.offerrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.offerlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.offerfinished = true;
    }
  } else if (active.value == 3) {
    //
    // console.log("执行2");
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.paymentpage = 0;
      return;
    }

    // 判断是下刷新海是触底  等待付款
    if (orderdata.value.paymentrefreshing == true) {
      orderdata.value.paymentlist = [
        ...res.data?.data,
        ...orderdata.value.paymentlist,
      ];
    } else {
      orderdata.value.paymentlist = [
        ...orderdata.value.paymentlist,
        ...res.data?.data,
      ];
    }

    loading.value = false;
    orderdata.value.paymentrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.paymentlist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.paymentfinished = true;
    }
  } else if (active.value == 4) {
    //
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.purchasepage = 0;

      return;
    }
    // 判断是下刷新海是触底  正在购买
    if (orderdata.value.purchaserefreshing == true) {
      orderdata.value.purchaselist = [
        ...res.data?.data,
        ...orderdata.value.purchaselist,
      ];
    } else {
      orderdata.value.purchaselist = [
        ...orderdata.value.purchaselist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    orderdata.value.purchaserefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.purchaselist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.purchasefinished = true;
    }
  } else if (active.value == 5) {
    //
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.completepurchasepage = 0;

      return;
    }
    // 判断是下刷新海是触底 采购完了
    if (orderdata.value.completerefreshing == true) {
      orderdata.value.completepurchaselist = [
        ...res.data?.data,
        ...orderdata.value.completepurchaselist,
      ];
    } else {
      orderdata.value.completepurchaselist = [
        ...orderdata.value.completepurchaselist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    orderdata.value.completerefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.completepurchaselist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.completefinished = true;
    }
  } else if (active.value == 6) {
    //
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.completedeliverypage = 0;

      return;
    }
    // 判断是下刷新海是触底  出货完成
    if (orderdata.value.deliveryrefreshing == true) {
      orderdata.value.completedeliverylist = [
        ...res.data?.data,
        ...orderdata.value.completedeliverylist,
      ];
    } else {
      orderdata.value.completedeliverylist = [
        ...orderdata.value.completedeliverylist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    orderdata.value.deliveryrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.completedeliverylist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.deliveryfinished = true;
    }
  } else if (active.value == 7) {
    //
    // 首先判断数据是不是当前状态的，防止客户切换汰换数据加载错乱
    if (res.data.data.length > 0 && local_active != active.value) {
      orderdata.value.cancelpage = 0;
      return;
    }
    // 判断是下刷新海是触底  已取消
    if (orderdata.value.cancelrefreshing == true) {
      orderdata.value.cancellist = [
        ...res.data?.data,
        ...orderdata.value.cancellist,
      ];
    } else {
      orderdata.value.cancellist = [
        ...orderdata.value.cancellist,
        ...res.data?.data,
      ];
    }
    loading.value = false;
    orderdata.value.cancelrefreshing = false;
    // 进行判断如果数据记载完成没有数据底部显示提示信息
    if (
      orderdata.value.cancellist.length == res.data.total ||
      res.data?.data.length < pageSize.value
    ) {
      orderdata.value.cancelfinished = true;
    }
  }
};
// 页面下拉触发
const onRefresh = () => {
  onLoad();
};
// 搜索的订单状态
const orderstate = ref(null);
// 搜索框搜索
const search = async () => {
  if (searchkeyword.value !== "") {
    Toast.loading({
      duration: 0,
      message: proxy.$fanyi("加载中..."),
      forbidClick: true,
    });
    const res = await proxy.$api.orderList({
      status_name: "全部",
      page: 1,
      pageSize: 20,
      keywords: searchkeyword.value,
    });
    if (res.code !== 0) {
      Toast.clear();
      Toast.fail(proxy.$fanyi(res.msg));
      return;
    }

    if (res.data.data.length == 0) {
      Toast.clear();
      Toast(proxy.$fanyi("查询不到您输入的订单号"));
      return;
    }
    // // 定义table数据
    // table.value = {
    //   temporary: 0, //临时保存
    //   all: res.data.data.length, //全
    //   offer: 0, //报价中
    //   payment: 0, // 等待付款
    //   purchase: 0, // 正在购买
    //   completepurchase: 0, //购买完成
    //   completedelivery: 0, //出货完成
    //   cancel: 0, // 取消
    // };
    orderstate.value = res.data.data[0].status_name;
    // console.log(state);
    Toast.clear();
    active.value = 1;
    orderdata.value.alllist = res.data.data;
    orderdata.value.allfinished = true;
    //   alllist: [],
    // allpage: 0,
    // allfinished: false,
    // allrefreshing: false,2024012314470966-300001
  } else {
    Toast(proxy.$fanyi("可根据订单号搜索"));
  }
};
//搜索框变化事件
const changeinput = () => {};
// 点击下载订单
const upload = (item) => {
  proxy.$api
    .getOrderDetailDownloadToken({
      order_sn: item.order_sn,
    })
    .then((res) => {
      console.log(res);
      const a = document.createElement("a");
      a.href = `https://apiwww.rakumart.com/client/download.orderDetailDownload?order_sn=${item.order_sn}&downloadToken=${res.data.downloadToken}`;
      document.body.appendChild(a);
      a.click();
    });
};
// 前往支付
const payment = (item) => {
  proxy.$fun.routerToPage("/OrderDetails?order_sn=" + item.order_sn);
};

// 复制单号
const copySn = (order_sn) => {
  navigator.clipboard.writeText(order_sn);
  proxy.$message.primary(proxy.$fanyi("复制成功"));
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

order_sn .headBox {
  span {
    font-size: 32px;
    font-family: PingFang SC !important;
    font-weight: 600;
    color: #000000;
  }
}

:deep().van-overlay {
  opacity: 0.4;
}

:deep() .van-icon-cross {
  width: 19px;
  height: 18px;
  top: 20px;
  right: 28px;
  font-size: 28px;
}

.search {
  width: 570px;
}

.content {
  width: 100%;
  min-height: 1380px;
  background-color: #f6f6f6;

  :deep() .van-tab__text {
    font-size: 28px;

    font-weight: 500;
  }

  :deep() .van-tabs__line {
    background: #b4272b;
  }

  :deep().van-tabs__wrap {
    height: 68px;
    position: sticky;
    top: 126px;
    z-index: 999;
  }

  .main {
    width: 100%;
    min-height: 1380px;
    background-color: #f6f6f6;
    padding: 30px;
    padding-top: 20px;

    .item {
      position: relative;
      width: 690px;
      // height: 329px;
      background: #ffffff;
      border-radius: 6px;
      padding: 30px 30px 15px;
      margin-bottom: 20px;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 28px;

        .Singlenumbe {
          font-size: 28px;

          font-weight: 400;
          color: #000000;
        }

        .state {
          font-size: 24px;

          font-weight: 400;
          color: #b4272b;
        }
      }

      p {
        margin-bottom: 10px;
        height: 36px;
        display: flex;
        align-items: center;

        .left-title {
          font-size: 24px;

          font-weight: 400;
          color: #999999;
        }

        .right-text {
          font-size: 24px;

          font-weight: 500;
          color: #000000;
        }
      }

      .upload {
        position: absolute;
        width: 160px;
        height: 60px;
        background: #2755b4;
        border-radius: 6px;
        right: 30px;
        bottom: 30px;
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .quxiao {
        color: black;
        background: #ffffff;
        border: 1px solid #dfdfdf;
      }
      .quxiao.quanbu {
        bottom: 120px;
      }
      .xiazai {
        position: absolute;
        bottom: 102px !important;
      }

      .fukuan {
        position: absolute;
        right: 30px;
        width: 160px;
        height: 60px;
        background: #b4272b;
        border-radius: 6px;
        bottom: 30px;
        font-size: 28px;

        font-weight: 400;
        color: #ffffff;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.noData {
  padding-top: 180px;
  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      width: 294px;
      height: 280px;
      margin-bottom: 42px;
    }
    span {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 40px;
      color: rgba(153, 153, 153, 1);
    }
  }
}
</style>
